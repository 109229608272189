import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Link } from '@keytrade/components-link';
import { Icon } from '@keytrade/components-icon';
import { LocalizedLink as GatsbyLink } from '@plugins/keytrade-localization/LocalizedLink';

import {
  SectionTitle,
  PopularTopicsWrapper,
} from '@/components/support/StyledComponents';
import Title from '@/components/Title';
import Page, { DefaultPaths, PageInfoType, PageType } from '@/components/Page';
import FAQItem from '@/components/support/FAQItem';
import CurrencyFormatter from '@/formatters/CurrencyFormatter';
import SupportSearch from '@/components/search/SupportSearch';
import BlockWrapper from '@/components/BlockWrapper';
import useTranslations from '@/hooks/useTranslations';

type SupportThemePageTemplateType = {
  pageContext: {
    supportPath: string;
  };
  data: {
    contentfulPage: {
      faqMainTheme: {
        popularTopics: Array<FAQTopic>;
        faqSubthemes: Array<FAQSubTheme>;
      };
    };
  };
};
type FAQTopic = {
  text: string;
};
type FAQItem = {
  supportArticlePage: {
    page: Array<PageInfoType>;
  };
};
type FAQSubTheme = {
  title: string;
  faqs: Array<FAQItem>;
};

type Props = PageType &
  SupportThemePageTemplateType & {
    location?: URL & {
      state?: {
        searchValue?: string;
      };
    };
  };

const FAQWrapper = styled.div`
  margin-bottom: 4.8rem;
  &:last-child {
    margin-bottom: 4rem;
  }
`;

const SupportThemePageTemplate: React.FC<Props> = (props) => {
  const content = props.data.contentfulPage.pageType.faqMainTheme;
  const defaultPaths: DefaultPaths = {
    supportPath: props.pageContext.supportPath,
  };
  const [isSearchingSupport, setIsSearchingSupport] = useState(false);
  const { FormattedMessage } = useTranslations();

  return (
    <Page {...props}>
      <SupportSearch
        {...props}
        locale={props.data.contentfulPage.node_locale}
        setParentSearchingSupport={setIsSearchingSupport}
        location={props.location}
      >
        {!isSearchingSupport && (
          <BlockWrapper color='Transparent' padding='xsmall'>
            <div>
              <Link
                render={() => (
                  <GatsbyLink to={props.pageContext.supportPath}>
                    <Icon icon='icn_longArrowLeft' />
                    <CurrencyFormatter>
                      <FormattedMessage id='keytrade_support' />
                    </CurrencyFormatter>
                  </GatsbyLink>
                )}
                isSemiBold
                margin='0 0 2rem 0'
              />
              <Title size='xxl' level='h1' margin='0 0 4.8rem 0'>
                {content.title}
              </Title>
              {content.popularTopics && (
                <>
                  <SectionTitle>
                    <CurrencyFormatter>
                      <FormattedMessage id='popular_topics' />
                    </CurrencyFormatter>
                  </SectionTitle>
                  <PopularTopicsWrapper>
                    {content.popularTopics.map((topic) => (
                      <Link
                        key={topic.text}
                        kind='withBackground'
                        render={() => (
                          <GatsbyLink to='/'>{topic.text}</GatsbyLink>
                        )}
                      />
                    ))}
                  </PopularTopicsWrapper>
                </>
              )}
              {content.faqSubthemes.map((faqSubtheme) => (
                <FAQWrapper key={faqSubtheme.title}>
                  <SectionTitle>
                    <CurrencyFormatter>{faqSubtheme.title}</CurrencyFormatter>
                  </SectionTitle>
                  {React.Children.toArray(
                    faqSubtheme?.faqs?.map((faq) => {
                      return FAQItem({
                        ...faq,
                        defaultPaths: defaultPaths,
                        margin: '0 0 2rem 0',
                      });
                    }),
                  )}
                </FAQWrapper>
              ))}
            </div>
          </BlockWrapper>
        )}
      </SupportSearch>
    </Page>
  );
};

export default SupportThemePageTemplate;

export const pageQuery = graphql`
  query ContentfulSupportThemePageQuery(
    $contentfulId: String!
    $locale: String
  ) {
    contentfulPage(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $locale }
    ) {
      node_locale
      seoMetadata {
        ...SeoMetaDataFragment
      }
      pageType {
        ... on ContentfulSupportThemePageType {
          faqMainTheme {
            title
            popularTopics {
              text
            }
            faqSubthemes {
              title
              faqs {
                ...FAQItemFragment
              }
            }
          }
        }
      }
    }
    contentfulNavigation(node_locale: { eq: $locale }) {
      ...NavigationFragment
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    allLanguages: allContentfulPage(
      filter: { contentful_id: { eq: $contentfulId } }
    ) {
      nodes {
        node_locale
        ...PagePathFragment
      }
    }
  }
`;
